<script>
import EventsItem from "./EventsItem.vue";

export default {
  components: { EventsItem },
  props: {
    events: Array,
    match_id: String
  },
  data() {
    return {};
  },
};
</script>
<template>
  <!-- <div v-if="events.length > 0" class="bg-white container mw-75 mt-4 px-4 py-2 rounded mx-auto  min-w-50 shadow mb-5"> -->
  <div v-if="events.length > 0" class="rounded mx-auto">
   
    <div class="row"
      v-for="event in events"
      :key="event.id"
    >
      <EventsItem
        :eventName="event.event_type_name"
        :home_away="event.place"
        :eventMinute="event.event_minute"
        :eventPlayer="event.player_name"
        :eventPlayerId="event.player_id"
        :eventTypeId="event.event_type_id"
        :eventSecondPlayer="event.related_player_name"
        :eventReason="event.event_reason_name"
        :eventReasonId="event.event_reason_id"
        :eventHomeGoals="event.home_goals"
        :eventAwayGoals="event.away_goals"
        :match_id="match_id"
      />
    </div>
  </div>
</template>

<style>
p {
  margin: 0;
}

svg {
  width: 20px;
}
</style>
