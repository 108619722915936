<script>
import Fixtures from "../../../../services/Fixtures";
import Swal from "sweetalert2";

export default {
  props: {
    statistics: Array,
    match_id: String,
  },
  data() {
    return {
      modify: false,
      tryingToSubmit: false,
      originalStatistics: [],
    };
  },
  methods: {
    calculatePercentage(value, totalOther) {
      const total = value + totalOther;
      return total ? ((value / total) * 100).toFixed(2) : 0;
    },
    toggleModify() {
      if (!this.modify) {
        this.originalStatistics = JSON.parse(JSON.stringify(this.statistics));
      }
      this.modify = !this.modify;
    },

    cancelChanges() {
      this.statistics = JSON.parse(JSON.stringify(this.originalStatistics));
      this.modify = false;
    },
    async submitStats() {
      try {
        this.tryingToSubmit = true;

        const ballPossession = this.statistics.find(
          (stat) => stat.stat_id === 21
        );
        if (ballPossession) {
          const totalPossession =
            parseInt(ballPossession.home_points, 10) +
            parseInt(ballPossession.away_points, 10);
          if (totalPossession !== 100) {
            this.failedmsg("Ball possession must total 100");
            this.tryingToSubmit = false;
            return;
          }
        }
        const payload = {
          match_id: this.match_id,
          stats: this.statistics.map((stat) => ({
            ...stat,
            home_points: parseInt(stat.home_points, 10),
            away_points: parseInt(stat.away_points, 10),
          })),
        };
        console.log(this.statistics);
        const response = await Fixtures.updateSportMatchStats(
          this.match_id,
          payload
        );
        this.successmsg();
        this.modify = false;
        this.getSportMatchStats();
        this.tryingToSubmit = false;
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.tryingToSubmit = false;
      }
    },
    async getSportMatchStats() {
      try {
        const response = await Fixtures.getSportMatchStats(this.match_id);
        this.statistics = response.data.stats;
      } catch (error) {
        if (error.response.data.error === "No match stats") return;
        this.failedmsg(error.response.data.error);
      }
    },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Statistics have been saved",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 5000,
      });
    },
  },
};
</script>
<template>
  <div class="container mt-3">
    <div class="row mb-3">
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-danger btn-sm mr-2"
          v-show="modify"
          @click="cancelChanges"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
            />
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
            />
          </svg>
          Cancel
        </button>
        <button
          class="btn btn-success btn-sm"
          v-show="modify"
          @click="submitStats"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-check-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
            />
            <path
              d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"
            />
          </svg>
          <span class="">{{ tryingToSubmit ? "Saving..." : "Save" }}</span>
        </button>
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary btn-sm"
          v-show="!modify"
          @click="toggleModify"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pen"
            viewBox="0 0 16 16"
          >
            <path
              d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"
            />
          </svg>
          Modify
        </button>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div
        v-for="(stat, index) in statistics"
        :key="index"
        class="col-md-5 m-3 shadow-sm"
      >
        <h6>{{ stat.stat_name }}</h6>
        <div v-show="!modify">
          <div class="d-flex justify-content-between mb-2">
            <p>{{ stat.home_points }}</p>
            <p>{{ stat.away_points }}</p>
          </div>
          <div class="progress mb-2">
            <div
              class="progress-bar bg-primary"
              :style="{
                width:
                  calculatePercentage(stat.home_points, stat.away_points) + '%',
              }"
              role="progressbar"
              :aria-valuenow="
                calculatePercentage(stat.home_points, stat.away_points)
              "
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            <div
              class="progress-bar bg-success"
              :style="{
                width:
                  calculatePercentage(stat.away_points, stat.home_points) + '%',
              }"
              role="progressbar"
              :aria-valuenow="
                calculatePercentage(stat.away_points, stat.home_points)
              "
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div class="d-flex justify-content-between">
            <span>Home Team</span>
            <span>Away Team</span>
          </div>
        </div>
        <div v-show="modify">
          <div class="form-group">
            <label for="home-range" class="form-label text-primary">
              Home Team
            </label>
            <input
              type="range"
              class="form-range home-range"
              id="home-range"
              v-model="stat.home_points"
              :min="0"
              :max="stat.stat_id === 21 ? 100 : 25"
            />
            <span>{{ stat.home_points }}</span>
          </div>
          <div class="form-group text-end">
            <label for="away-range" class="form-label text-end text-success">
              Away Team
            </label>
            <input
              type="range"
              class="form-range away-range"
              id="away-range"
              v-model="stat.away_points"
              :min="0"
              :max="stat.stat_id === 21 ? 100 : 25"
            />
            <span>{{ stat.away_points }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.away-range {
  direction: rtl;
}
.away-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #34c38f;
}
.away-range::-webkit-slider-runnable-track {
  background-color: #d2f0e3;
}
.home-range::-webkit-slider-runnable-track {
  background-color: #d6dcf9;
}

/* mozila browser */
.away-range::-moz-range-track {
  background-color: #d2f0e3;
}
.home-range::-moz-range-track {
  background-color: #d6dcf9;
}
.away-range::-moz-range-thumb {
  background: #34c38f;
}
</style>
