<script>
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";

export default {
  props: {
    team: Number, //0 for home, 1 for away
    match_id: String,
    lineupHome: Array,
    lineupAway: Array,
    penaltyIndex: Number,
  },
  emits: ["goalResult", "response"],
  data() {
    return {
      csrf_token: localStorage.getItem("csrf_token"),
      showModal: false,
      tryingToEdit: false,
      match_minute: null,
      player_id: null,
      related_player_id: null,
      tryingToSubmit: false,
      selectedGoal: 9,
      options: [
        { value: 9, label: "Score" },
        { value: 7, label: "Missed" },
      ],
    };
  },
  computed: {
    players() {
      return this.team ? this.lineupAway : this.lineupHome;
    },
    opposingPlayers() {
      return this.team ? this.lineupHome : this.lineupAway;
    },
    team_id() {
      return this.team;
    },
  },
  methods: {
    async addSportMatchEvent() {
      try {
        this.tryingToSubmit = true;
        const payload = {
          event: this.selectedGoal === 9? "Goal": "Missed penalty",
          player_id: this.player_id,
          match_id: this.match_id,
          home_away: this.team ? -1 : 1,
        };
        if (this.selectedGoal === 9) {
          payload.event_reason_id = 9;
          payload.event_reason_name = "Penalty";
        }
        await Fixtures.addSportMatchEvent(this.match_id, payload);
        this.$emit("goalResult", {
          team: this.team === 0 ? "home" : "away",
          index: this.penaltyIndex,
          result: this.selectedGoal,
        });
        this.successmsg();
        this.tryingToSubmit = false;
        this.closeModal();
        this.$emit("response");
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.tryingToSubmit = false;
      }
    },
    closeModal() {
      this.showModal = false;
    },

    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${this.team ? "Away" : "Home"} ${this.selectedGoal === 9 ? "scored!" : "missed!"}`,
        showConfirmButton: false,
        timer: 2000,
      });
    },

    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 50000,
      });
    },
  },
};
</script>

<template>
  <b-modal
    id="penalty"
    v-model="showModal"
    :title="team ? 'Penalty - Away' : 'Penalty - Home'"
    title-class="text-black font-18"
    body-class="p-3"
    hide-footer
    centered
  >
    <form @submit.prevent="addSportMatchEvent">
      <div class="row">
        <div class="col-12">
          <div class="d-flex font-size-14 mb-3">
            <label
              v-for="option in options"
              :key="option.value"
              class="radio-inline"
            >
              <input
                type="radio"
                v-model="selectedGoal"
                :value="option.value"
              />
              {{ option.label }}
            </label>
          </div>
          <div class="mb-3">
            <label class="control-label form-label">
              Choose Player
            </label>

            <select
              v-model="player_id"
              class="form-control form-select"
              name="player_id"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select a player</b-form-select-option
              >
              <option
                v-for="player in players"
                :key="player.player_id"
                :value="player.player_id"
              >
                {{ player.player_name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="closeModal">Close</b-button>
        <b-button
          type="submit"
          variant="primary"
          class="ms-3"
          :disabled="tryingToSubmit"
        >
          {{ this.tryingToSubmit ? "Loading..." : "Submit" }}
        </b-button>
      </div>
      <input type="hidden" name="csrf_token" v-model="csrf_token" />
    </form>
  </b-modal>
</template>

<style>
input[type="radio"] {
  accent-color: #5b73e8;
}
.radio-inline {
  margin-right: 1.5rem !important;
}
</style>
