<script>
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";

export default {
  props: {
    team: Number, // 0 for home, 1 for away
    match_id: String,
    lineupHome: Array,
    lineupAway: Array,
    events: Array,
  },
  emits: ["response"],
  data() {
    return {
      csrf_token: localStorage.getItem("csrf_token"),
      showModal: false,
      match_minute: null,
      player_id: null,
      tryingToSubmit: false,
    };
  },
  computed: {
    players() {
      return this.team ? this.lineupAway : this.lineupHome;
    },
    team_id() {
      return this.team;
    },
  },
  methods: {
    async addSportMatchEvent() {
      try {
        this.tryingToSubmit = true;
        const yellowCards = this.events.filter(
          (event) =>
            event.player_id === this.player_id && event.event_type_id === 6
        );
        let eventType = "Yellow Card";
        if (yellowCards.length >= 1) {
          eventType = "Second Yellow - Red Card";
        }

        const payload = {
          event: eventType,
          player_id: this.player_id,
          match_id: this.match_id,
          home_away: this.team ? -1 : 1,
        };
        await Fixtures.addSportMatchEvent(this.match_id, payload);

        this.successmsg();
        this.tryingToSubmit = false;
        this.closeModal();
        this.$emit("response");
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.tryingToSubmit = false;
      }
    },

    closeModal() {
      this.showModal = false;
    },

    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Change has been saved",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 5000,
      });
    },
  },
};
</script>

<template>
  <b-modal
    id="yellow_card"
    v-model="showModal"
    :title="team ? 'Yellow Card - Away' : 'Yellow Card - Home'"
    title-class="text-black font-18"
    body-class="p-3"
    hide-footer
    centered
  >
    <form @submit.prevent="addSportMatchEvent">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="control-label form-label">Choose Player</label>
            <select
              v-model="player_id"
              class="form-control form-select"
              name="player_id"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select a player</b-form-select-option
              >
              <option
                v-for="player in players"
                :key="player.player_id"
                :value="player.player_id"
              >
                {{ player.player_name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="closeModal">Close</b-button>
        <b-button
          type="submit"
          variant="primary"
          class="ms-3"
          :disabled="tryingToSubmit"
        >
          {{ tryingToSubmit ? "Loading..." : "Submit" }}
        </b-button>
      </div>
      <input type="hidden" name="csrf_token" v-model="csrf_token" />
    </form>
  </b-modal>
</template>
