<script>
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";

export default {
  props: {
    match_id: String,
    match_status: String,
    match_status_id: Number,
  },
  emits: ["statusUpdated"],
  data() {
    return {
      csrf_token: localStorage.getItem("csrf_token"),
      showModal: false,
      tryingToEdit: false,
      tryingToSubmit: false,
      statuses: [],
      selectedStatus: null,
      filteredStatuses: [],
    };
  },
  methods: {
    async getSportMatchStatuses() {
      try {
        const response = await Fixtures.getSportMatchStatuses();
        this.statuses = response.data.match_status;
        this.filterStatuses();
      } catch (error) {
        this.failedmsg(error.response.data.error);
      }
    },
    filterStatuses() {
      if (this.match_status === "Fixture") {
        this.filteredStatuses = this.statuses.filter((status) => {
          return (
            status.match_status === "Cancelled" ||
            status.match_status === "Postponed"
          );
        });
      } else if (this.match_status === "Playing") {
        this.filteredStatuses = this.statuses.filter((status) => {
          return status.match_status === "Suspended";
        });
      } else if (this.match_status === "Suspended") {
        this.filteredStatuses = this.statuses.filter((status) => {
          return (
            status.match_status === "Playing" ||
            status.match_status === "Cancelled"
          );
        });
      } else if (this.match_status === "Postponed") {
        this.filteredStatuses = this.statuses.filter((status) => {
          return (
            status.match_status === "Cancelled" ||
            status.match_status === "Fixture"
          );
        });
      } else {
        this.filteredStatuses = [];
      }
    },
    async updateSportMatch() {
      try {
        this.tryingToSubmit = true;
        const selectedStatusObj = this.filteredStatuses.find(
          (status) => status.match_status === this.selectedStatus
        );
        const payload = {
          match_id: this.match_id,
          match_status: this.selectedStatus,
          match_status_id: selectedStatusObj.match_status_id,
        };
        const response = await Fixtures.updateSportMatch(
          this.match_id,
          payload
        ).then((response) => response.data);
        this.$emit("statusUpdated", response.match);
      } catch (error) {
        this.failedmsg(error.response.data.error);
      } finally {
        this.$root.$emit("statusUpdated");
        this.successmsg();
        this.tryingToSubmit = false;
        this.closeModal();
      }
    },
    closeModal() {
      this.showModal = false;
    },

    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Success!",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 50000,
      });
    },
  },
  mounted() {
    this.selectedStatus = this.match_status;
    this.getSportMatchStatuses();
  },
  watch: {
    match_status: function(newVal, oldVal) {
      this.selectedStatus = newVal;
      this.filterStatuses();
    },
  },
};
</script>

<template>
  <b-modal
    id="change_status"
    v-model="showModal"
    title="Change Status"
    title-class="text-black font-18"
    body-class="p-3"
    hide-footer
    centered
  >
    <form @submit.prevent="updateSportMatch">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="control-label form-label">Choose Status</label>
            <select
              v-model="selectedStatus"
              class="form-control form-select"
              name="status"
              type="select"
            >
              <b-form-select-option :value="null"
                >Please select status</b-form-select-option
              >
              <option
                v-for="status in filteredStatuses"
                :key="status.match_status_id"
                :value="status.match_status"
              >
                {{ status.match_status }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="closeModal">Close</b-button>
        <b-button
          type="submit"
          variant="primary"
          class="ms-3"
          :disabled="tryingToSubmit"
        >
          {{ this.tryingToSubmit ? "Loading..." : "Submit" }}
        </b-button>
      </div>
      <input type="hidden" name="csrf_token" v-model="csrf_token" />
    </form>
  </b-modal>
</template>
